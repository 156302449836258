<template>
    <nuxt-link v-if="bannerHeader?.image_bg" :to="bannerHeader?.btn_link" class="block w-full">
        <div class="w-full h-full">
            <NuxtImg
                v-if="bannerHeader?.image_bg"
                :src="getThumbnail(bannerHeader?.image_bg, 2000, true)"
                width="2000"
                sizes="sm:100vw md:50vw lg:2000px "
                loading="lazy"
                alt="Thumbail Event"
                :placeholder="[50, 25, 75, 5]"
                class="w-full h-full object-cover hidden lg:block"
            />
            <NuxtImg
                v-if="bannerHeader?.image_mobile"
                :src="getThumbnail(bannerHeader?.image_mobile, 2000, true)"
                width="2000"
                sizes="sm:100vw md:50vw lg:2000px "
                loading="lazy"
                alt="Thumbail Event"
                :placeholder="[50, 25, 75, 5]"
                class="w-full h-full object-cover lg:hidden"
            />
            <!-- <img src="/images/home/black-friday-header.png" alt="" class="w-full h-full object-cover" /> -->
        </div>
    </nuxt-link>
</template>

<script setup lang="ts">
const { getBanner } = useBanner()
const banners = await getBanner({ name: 'Event' })
const bannerHeader = ref(
    banners && banners?.banners?.banner_items && banners?.banners?.banner_items.length > 0
        ? banners?.banners?.banner_items[0]
        : {}
)
</script>

<style></style>
